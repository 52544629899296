import clsx from 'clsx';
import React from 'react';
import { DashboardAccordionCard } from '../card-accordion/DashboardAccordionCard';
import { DashboardAccordionCardTable } from '../card-accordion/DashboardAccordionCardTable';
import { IColsTable, IDashboardTableRows } from '../types';
import { DashboardAccordionCardProvider } from './DashboardAccordionCardProvider';

interface IDashboardAccordionList {
  titleKey: string;
  panelProps: {
    columns: IColsTable[];
    rows: IDashboardTableRows[];
  };
}

interface Props {
  list: IDashboardAccordionList[];
}

function useComponentState(props: Props) {
  return {
    ...props,
  };
}

export const DashboardAccordionCardList: React.FC<Props> = (props) => {
  const { list } = useComponentState(props);

  return (
    <div
      className={clsx('mt-4 items-start flex-grow lg:mt-0', list.length > 1 && 'xxl:grid xxl:grid-cols-2 xxl:gap-4')}
    >
      {list.map((item) => (
        <DashboardAccordionCardProvider key={item.titleKey}>
          <DashboardAccordionCard titleKey={item.titleKey}>
            <DashboardAccordionCardTable {...item.panelProps} />
          </DashboardAccordionCard>
        </DashboardAccordionCardProvider>
      ))}
    </div>
  );
};
