import countries from '@values/countries';

export function transformCountryField(data: Record<string, string>[]) {
  const transformedData = data.map((item) => {
    const { country: countryCode } = item;
    const countryData = countries.find((v) => v.code === countryCode);
    const country = countryData ? countryData.name : '';

    return {
      ...item,
      country,
    };
  });

  return transformedData;
}
