import React, { useCallback, useState } from 'react';
import { ComposableMap, Geographies, ZoomableGroup } from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';

import { VolunteersSaturationMapCountry } from './VolunteersSaturationMapGeography';

import { WorldCountryGeography } from './types';
import { getWorldCountryTotalVolunteers } from './utils/volunteer-saturation-map.utils';

import useMaxWidthMediaQuery from '@hooks/useMaxWidthMediaQuery';
import { useTranslation } from 'react-i18next';

export const worldGeographyUrl = '/maps/world-countries.json';

export interface VolunteersSaturationMapChartProps {
  data: Record<string, number>;

  setTooltipContent: (content: string) => void;
}

const useComponentState = ({ data, setTooltipContent }: VolunteersSaturationMapChartProps) => {
  const { t } = useTranslation();
  const isSmallScreen = useMaxWidthMediaQuery('md');

  const onCountryHover = useCallback((geography: WorldCountryGeography) => {
    if (!geography || !geography.properties) {
      return;
    }

    const totalVolunteers = getWorldCountryTotalVolunteers(data, geography);
    if (totalVolunteers <= 0) {
      return;
    }

    setTooltipContent(`${t('volunteers-amount')}: ${totalVolunteers} (${geography.properties.name})`);
  }, []);

  const onCountryHoverEnd = useCallback(() => {
    setTooltipContent('');
  }, []);

  return {
    data,
    mapCenter: (isSmallScreen ? [23.5, 45.6] : [0, 0]) as [number, number],
    mapZoom: isSmallScreen ? 6 : 1,
    onCountryHover,
    onCountryHoverEnd,
  };
};

const VolunteersSaturationMapChart: React.FC<VolunteersSaturationMapChartProps> = React.memo((props) => {
  const { data, mapCenter, mapZoom, onCountryHover, onCountryHoverEnd } = useComponentState(props);

  return (
    <div data-tip="" className="bg-[#006994]">
      <ComposableMap>
        <ZoomableGroup center={mapCenter} zoom={mapZoom}>
          <Geographies geography={worldGeographyUrl}>
            {({ geographies }) =>
              geographies.map((geo: WorldCountryGeography) => (
                <VolunteersSaturationMapCountry
                  key={geo.rmsKey}
                  geography={geo}
                  totalVolunteers={getWorldCountryTotalVolunteers(data, geo)}
                  onHover={onCountryHover}
                  onHoverEnd={onCountryHoverEnd}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
});

export interface VolunteersSaturationMapProps {
  data: Record<string, number>;
}

export const VolunteersSaturationMap: React.FC<VolunteersSaturationMapProps> = ({ data }) => {
  const [tooltipContent, setTooltipContent] = useState('');

  return (
    <div>
      <VolunteersSaturationMapChart data={data} setTooltipContent={setTooltipContent} />

      <ReactTooltip>{tooltipContent}</ReactTooltip>
    </div>
  );
};
