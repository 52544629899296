export enum DashboardBasicCardVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export enum DashboardCategories {
  Volunteer = 'volunteers',
  Applicants = 'applicants',
  Applications = 'applications',
  OnlineHelp = 'online-help',
  HandToHandHelp = 'hand-to-hand-help',
  VolunteersMap = 'volunteers-map',
}
