import useMaxWidthMediaQuery from '@hooks/useMaxWidthMediaQuery';
import React, { createContext, useContext, useRef } from 'react';

interface AccordionCardProviderProps {
  cardRef: HTMLDivElement;
  scrollCardIntoView: () => void;
}

export const DashboardAccordionCardContext = createContext({} as AccordionCardProviderProps);
export const useDashboardAccordionCardContext = (): AccordionCardProviderProps =>
  useContext(DashboardAccordionCardContext);

function useComponentState() {
  const isSmallScreen = useMaxWidthMediaQuery('lg');
  const cardRef = useRef<HTMLDivElement>(null);
  const scrollCardIntoView = () => {
    isSmallScreen &&
      window.setTimeout(() => {
        cardRef.current!.scrollIntoView();
      }, 30);
  };

  const providerValues = {
    cardRef,
    scrollCardIntoView,
  };

  return {
    providerValues,
    DashboardAccordionCardContext,
  };
}

export const DashboardAccordionCardProvider: React.FC = ({ children }) => {
  const { providerValues, DashboardAccordionCardContext } = useComponentState();

  return (
    <DashboardAccordionCardContext.Provider value={providerValues}>{children}</DashboardAccordionCardContext.Provider>
  );
};
