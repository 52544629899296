import { useGetDashboardStatisticsQuery } from '@store/api/statistics.api';
import { useGetDashboardAccordionCardList } from '@widgets/dashboard/hooks/useGetDashboardAccordionCardList';
import { useGetDashboardBasicCardList } from '@widgets/dashboard/hooks/useGetDashboardBasicCardList';
import { dashboardVolunteersAccordionCardValues, dashboardVolunteersBasicCardValues } from '../constants';

export function useGetDashboardVolunteerStatisticList() {
  const { data } = useGetDashboardStatisticsQuery();
  const volunteersData = data?.volunteers;
  const basicCardList = useGetDashboardBasicCardList(dashboardVolunteersBasicCardValues, volunteersData);
  const accordionCardList = useGetDashboardAccordionCardList(dashboardVolunteersAccordionCardValues, volunteersData);

  const categoryProps = {
    basicCardList,
    accordionList: accordionCardList,
  };

  return categoryProps;
}
