import { DashboardCategories } from '@widgets/dashboard/enums';
import React from 'react';
import { DashboardCategory } from '../DashboardCategory';
import { useCategoryVisibility } from '../useCategoryVisibility';
import { useGetDashboardOnlineHelpStatistics } from './hooks/useGetDashboardOnlineHelpStatistics';

function useComponentState() {
  const categoryProps = useGetDashboardOnlineHelpStatistics();
  const isCategoryVisible = useCategoryVisibility(DashboardCategories.OnlineHelp);

  return {
    isCategoryVisible,
    categoryProps,
  };
}

export const DashboardCategoryOnlineHelp = () => {
  const { isCategoryVisible, categoryProps } = useComponentState();

  if (!isCategoryVisible) return null;
  return <DashboardCategory {...categoryProps} />;
};
