import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IDashboardAccordionCardDefault } from '../types';
import { transformCountryField } from '../utils';
import { useTranslateColumnsTitle } from './useTranslateColumnsTitle';

const statisticsFieldsNotNeededTranslation = ['count', 'percentage', 'country'];

function isStatisticsFieldNeedTranslation(fieldName: string) {
  return !statisticsFieldsNotNeededTranslation.includes(fieldName);
}

export function useGetDashboardAccordionCardList(
  defaultAccordionCardValues: IDashboardAccordionCardDefault[],
  statisticsData: any,
) {
  return defaultAccordionCardValues.map((item) => {
    const { titleKey, columns, dataFieldKey } = item;
    const columnsTranslated = useTranslateColumnsTitle(columns);
    const inputData = statisticsData[dataFieldKey];
    const needTransformCountries = columns.some((item) => item.accessor === 'country');

    const dataOutput = !needTransformCountries ? inputData : transformCountryField(inputData);
    const dataOutputTranslated = useDashboardTableDataTranslated(dataOutput);

    const panelProps = {
      columns: columnsTranslated,
      rows: dataOutputTranslated,
    };

    return {
      titleKey,
      panelProps,
    };
  });
}

function useDashboardTableDataTranslated(data: any) {
  const { t } = useTranslation();
  if (!data.length) return data;
  const dataKeys = Object.keys(data[0]);
  const isDataNeedsTranslation = dataKeys.some(isStatisticsFieldNeedTranslation);
  if (!isDataNeedsTranslation) return data;

  const updatedData = data.map((item) => {
    const itemKeys = Object.keys(item);
    const updatedItem = {};
    itemKeys.forEach((fieldName) => {
      updatedItem[fieldName] = isStatisticsFieldNeedTranslation(fieldName) ? t(item[fieldName]) : item[fieldName];
    });

    return updatedItem;
  });

  return updatedData;
}
