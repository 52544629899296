import { emptySplitApi } from '../emptySplitApi';
import { DashboardStatistics } from './types';

const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getDashboardStatistics: build.query<DashboardStatistics, void>({
      query: () => ({ url: `/statistic/dashboard` }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetDashboardStatisticsQuery } = extendedApi;
