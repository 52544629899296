import { useMemo } from 'react';

import { useGetDashboardStatisticsQuery } from '@store/api/statistics.api';

export function useGetTotalVolunteersByCountries() {
  const { data } = useGetDashboardStatisticsQuery();
  const totalVolunteersByCountries = useMemo(
    () =>
      (data?.volunteers.volunteersByCountry || []).reduce((a, c) => {
        a[c.country] = c.count;

        return a;
      }, {} as Record<string, number>),
    [data?.volunteers.volunteersByCountry],
  );

  return totalVolunteersByCountries;
}
