import React from 'react';
import { DashboardBasicCard } from '../card-basic/DashboardBasicCard';
import { DashboardBasicCardProps } from '../card-basic/types';

interface Props {
  list: DashboardBasicCardProps[];
}

export const DashboardBasicCardList = (props: Props) => {
  const { list } = props;

  return (
    <div className="xxs:grid xxs:grid-cols-2 gap-4 lg:block lg:basis-[528px] lg:flex-grow-0 lg:flex-shrink-0 xxl:grid">
      {list.map((item) => (
        <DashboardBasicCard key={item.titleKey} {...item} />
      ))}
    </div>
  );
};
