import { Loader } from '@shared/ui/loader/Loader';
import { useGetDashboardStatisticsQuery } from '@store/api/statistics.api';
import { DashboardCategoryTabs } from '@widgets/dashboard/category-tabs/DashboardCategoryTabs';
import { DashboardCategoryApplicants } from '@widgets/dashboard/category/applicants/DashboardCategoryApplicants';
import { DashboardCategoryApplications } from '@widgets/dashboard/category/applications/DashboardCategoryApplications';
import { DashboardCategoryHandToHand } from '@widgets/dashboard/category/hand-to-hand/DashboardCategoryHandToHand';
import { DashboardCategoryOnlineHelp } from '@widgets/dashboard/category/online-help/DashboardCategoryOnlineHelp';
import { DashboardCategoryVolunteersMap } from '@widgets/dashboard/category/volunteers-map/DashboardCategoryVolunteerMap';
import { DashboardCategoryVolunteer } from '@widgets/dashboard/category/volunteers/DashboardCategoryVolunteer';
import { DashboardProvider } from '@widgets/dashboard/context/DashboardProvider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

function useComponentState() {
  const { isLoading, isSuccess } = useGetDashboardStatisticsQuery();
  const { t } = useTranslation();

  return {
    t,
    isLoading,
    isSuccess,
  };
}

const DashboardPage = () => {
  const { t, isLoading, isSuccess } = useComponentState();

  return (
    <div className="pt-6 pb-12 lg:pt-12 xl:pb-52">
      <div className="container_xl">
        <h1 className="text-2xl lg:text-4xl">{t('analytics')}</h1>

        <DashboardProvider>
          <DashboardCategoryTabs />
          {isLoading && <Loader className="mt-12 mx-auto" />}
          {isSuccess && (
            <>
              <DashboardCategoryVolunteer />
              <DashboardCategoryApplicants />
              <DashboardCategoryApplications />
              <DashboardCategoryOnlineHelp />
              <DashboardCategoryHandToHand />
              <DashboardCategoryVolunteersMap />
            </>
          )}
        </DashboardProvider>
      </div>
    </div>
  );
};

export default DashboardPage;
