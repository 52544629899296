import { useTranslation } from 'react-i18next';
import { IColsTable } from '../types';

export function useTranslateColumnsTitle(columns: IColsTable[]) {
  const { t } = useTranslation();
  return columns.map((item) => {
    return {
      ...item,
      Header: t(item.Header),
    };
  });
}
