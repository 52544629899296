import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { useGetIconsOptimized } from '@shared/hooks/useGetIconsOptimized';
import { useMemo } from 'react';
import { IDashboardBasicCardDefault } from '../types';

export function useGetDashboardBasicCardList(
  defaultBasicCardValues: IDashboardBasicCardDefault[],
  statisticsData: any,
) {
  const imagesOprimized = useGetIconsOptimized();

  return useMemo(
    () =>
      defaultBasicCardValues.map((item, index: number) => {
        const { titleKey, dataFieldKey, icon: iconData } = item;
        const optimizedImage = iconData ? getOptimezedImage(imagesOprimized, iconData?.name) : null;
        const icon = iconData && optimizedImage ? { ...iconData, image: optimizedImage.content } : null;

        return {
          titleKey,
          isFirstCard: index === 0,
          amount: statisticsData[dataFieldKey] ?? 0,
          icon,
        };
      }),
    [defaultBasicCardValues, statisticsData],
  );
}

function getOptimezedImage(allOptimizedImages: any, iconName: string) {
  return allOptimizedImages.find((v) => v.name === iconName);
}
