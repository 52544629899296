import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface TabBasicProps {
  eventKey: string;
  label: string;
  isActive: boolean;
  isLoading?: boolean;
  indication?: null | number;
  className?: string;
  onClick: (key: string) => void;
  children?: ReactNode;
}

export const TabBasic: React.FC<TabBasicProps> = (props) => {
  const { isActive, label, eventKey, indication = null, className = '', isLoading = false, onClick, children } = props;
  const { t } = useTranslation();

  const tabCn = clsx(
    'relative whitespace-nowrap text-sm cursor-pointer after:content-[""] after:block after:w-full after:absolute after:-bottom-2 after:left-0 after:h-[2px] transition-colors md:text-md md:text-center',
    isActive ? 'text-black after:bg-black' : 'text-black/50 after:bg-transparent',
    indication !== null && 'flex flex-col items-center',
    className,
  );
  const indicationCn = clsx(
    'mb-1 px-[10px] py-1 rounded-full text-xxxs md:text-xs md:ml-0',
    isLoading && 'h-[26px] w-[26px] skeleton',
    isActive ? 'bg-black text-white' : 'bg-black-7 text-black-50',
  );

  return (
    <li className={tabCn} onClick={() => onClick(eventKey)}>
      {indication !== null && <span className={indicationCn}>{!isLoading && indication}</span>}
      {t(label)}
      {children}
    </li>
  );
};
