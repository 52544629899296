import { useMemo } from 'react';
import { useDashboardContext } from '../context/DashboardProvider';
import { categoryTabsState } from './state';

export function useGetCategoryTabs() {
  const { activeCategory, handleChangeActiveCategory } = useDashboardContext();

  const categoryTabs = useMemo(
    () =>
      categoryTabsState.map((item) => ({
        label: item.labelKey,
        eventKey: item.eventKey,
        isActive: item.isActiveCheck(item.eventKey, activeCategory),
      })),
    [categoryTabsState, activeCategory],
  );

  return [categoryTabs, handleChangeActiveCategory] as const;
}
