import { WorldCountryGeography } from '../types';

export const getWorldCountryCode = (geography: WorldCountryGeography) =>
  String(geography.properties['Alpha-2']).toLowerCase();

export const getWorldCountryTotalVolunteers = (data: Record<string, number>, geography: WorldCountryGeography) =>
  data[getWorldCountryCode(geography)] || 0;

export const getVolunteersSaturationMapCountryColor = (totalVolunteers: number) => {
  if (totalVolunteers <= 0) {
    return '#ffffff';
  }

  if (totalVolunteers <= 5) {
    return '#9dcd93';
  }

  if (totalVolunteers <= 35) {
    return '#4eb566';
  }

  if (totalVolunteers <= 80) {
    return '#14883f';
  }

  return '#103a22';
};
