import { TablePagination } from '@shared/ui/table/TablePagination';
import { TableSortIcon } from '@shared/ui/table/TableSortIcon';
import React, { useMemo, useRef } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';

interface ITableColumn {
  Header: string;
  accessor: string;
}

interface Props {
  columns: ITableColumn[];
  rows: { [key: string]: string }[];
}

function useComponentState(props: Props) {
  const { columns, rows } = props;
  const columnsOutput = useMemo(() => columns, []);
  const rowsOutput = useMemo(() => rows, []);
  const tableInstance = useTable(
    { columns: columnsOutput, data: rowsOutput, initialState: { pageIndex: 0, pageSize: 10 } },
    useSortBy,
    usePagination,
  );

  const {
    state: { pageIndex, pageSize },
    pageOptions,
    pageCount,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
  } = tableInstance;

  const tablePaginationProps = {
    pageIndex,
    pageSize,
    pageOptions,
    pageCount,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
  };

  return {
    ...tableInstance,
    tablePaginationProps,
  };
}

export const DashboardAccordionCardTable = (props: Props) => {
  const { tablePaginationProps, getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useComponentState(props);

  return (
    <>
      <table {...getTableProps()} className="table-fixed w-full border-collapse text-xs">
        <thead className="hidden sm:h-auto sm:overflow-auto sm:table-header-group">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  scope="col"
                  className="py-3 pl-4 first:pl-0 text-left text-black-50"
                >
                  <div className="flex items-center">
                    {column.render('Header')}
                    <TableSortIcon {...column} />
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="block mt-3 first:mt-0 sm:table-row sm:p-0">
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      data-label={headerGroups[0].headers[index].Header}
                      className="block border-t first:border-t-0 border-black-7 text-right py-3 px-3 sm:first:pl-0 bg-gray/10 sm:bg-gray/0 sm:table-cell sm:border-0 sm:pl-4 sm:text-left before:content-[attr(data-label)] before:float-left before:font-medium sm:before:hidden"
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <TablePagination {...tablePaginationProps} />
    </>
  );
};
