export const dashboardApplicationsBasicCardValues = [
  { titleKey: 'completed-applications-amount', dataFieldKey: 'totalCompletedApplications' },
  { titleKey: 'cancelled-applications-amount', dataFieldKey: 'declinedApplications' },
  { titleKey: 'active-applications-amount', dataFieldKey: 'totalApplications' },
];

export const dashboardApplicationsAccordionCardValues = [
  {
    titleKey: 'active-needs-amount',
    dataFieldKey: 'needsFromApplications',
    columns: [
      { Header: 'need', accessor: 'name' },
      { Header: 'quantity', accessor: 'count' },
      { Header: 'percent', accessor: 'percentageFromTotal' },
    ],
  },
];
