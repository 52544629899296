import { useGetDashboardStatisticsQuery } from '@store/api/statistics.api';
import { useGetDashboardAccordionCardList } from '@widgets/dashboard/hooks/useGetDashboardAccordionCardList';
import { useGetDashboardBasicCardList } from '@widgets/dashboard/hooks/useGetDashboardBasicCardList';
import { dashboardApplicantsAccordionCardValues, dashboardApplicantsBasicCardValues } from '../constants';

export function useGetDashboardApplicantsStatisticList() {
  const { data } = useGetDashboardStatisticsQuery();
  const applicantsData = data?.applicants;
  const basicCardList = useGetDashboardBasicCardList(dashboardApplicantsBasicCardValues, applicantsData);
  const accordionCardList = useGetDashboardAccordionCardList(dashboardApplicantsAccordionCardValues, applicantsData);

  const categoryProps = {
    titleKey: 'applicants',
    basicCardList,
    accordionList: accordionCardList,
  };

  return categoryProps;
}
