import { useGetDashboardStatisticsQuery } from '@store/api/statistics.api';
import { useGetDashboardAccordionCardList } from '@widgets/dashboard/hooks/useGetDashboardAccordionCardList';
import { useGetDashboardBasicCardList } from '@widgets/dashboard/hooks/useGetDashboardBasicCardList';
import { dashboardHandToHandAccordionCardValues, dashboardHandToHandBasicCardValues } from '../constants';

export function useGetDashboardHandToHandStatistics() {
  const { data } = useGetDashboardStatisticsQuery();
  const handToHandData = data?.handToHandHelp;
  const basicCardList = useGetDashboardBasicCardList(dashboardHandToHandBasicCardValues, handToHandData);
  const accordionCardList = useGetDashboardAccordionCardList(dashboardHandToHandAccordionCardValues, handToHandData);

  const categoryProps = {
    titleKey: 'hand-to-hand-help',
    basicCardList,
    accordionList: accordionCardList,
  };

  return categoryProps;
}
