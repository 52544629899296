import React, { useCallback } from 'react';
import { Geography } from 'react-simple-maps';

import { WorldCountryGeography } from './types';
import { getVolunteersSaturationMapCountryColor } from './utils/volunteer-saturation-map.utils';

export interface VolunteersSaturationMapCountryProps {
  geography: WorldCountryGeography;

  totalVolunteers: number;

  onHover?: (geography: WorldCountryGeography) => void;

  onHoverEnd?: (geography: WorldCountryGeography) => void;
}

const useComponentState = ({
  onHover,
  onHoverEnd,
  geography,
  totalVolunteers,
}: VolunteersSaturationMapCountryProps) => {
  const onMouseEnter = useCallback(() => {
    if (onHover) {
      onHover(geography);
    }
  }, [onHover]);

  const onMouseLeave = useCallback(() => {
    if (onHoverEnd) {
      onHoverEnd(geography);
    }
  }, [onHoverEnd]);

  const defaultColor = getVolunteersSaturationMapCountryColor(totalVolunteers);
  const hoverColor = totalVolunteers > 0 ? '#5ad09d' : defaultColor;

  const style = {
    default: {
      outline: 'none',
    },
    hover: {
      fill: hoverColor,
      outline: 'none',
      stroke: 'none',
    },
    pressed: {
      fill: hoverColor,
      outline: 'none',
      stroke: 'none',
    },
  };

  return {
    geography,
    fill: defaultColor,
    style,
    onMouseEnter,
    onMouseLeave,
  };
};

export const VolunteersSaturationMapCountry: React.FC<VolunteersSaturationMapCountryProps> = React.memo((props) => {
  const { geography, style, fill, onMouseEnter, onMouseLeave } = useComponentState(props);

  return (
    <Geography
      key={geography.rmsKey}
      fill={fill}
      geography={geography}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
});
