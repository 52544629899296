export const dashboardHandToHandBasicCardValues = [
  { titleKey: 'total-hand-applications-amount', dataFieldKey: 'totalHandToHandApplication' },
  { titleKey: 'completed-hand-application-amount', dataFieldKey: 'completedHandToHandApplication' },
  { titleKey: 'completed-hand-santa-application-amount', dataFieldKey: 'completedHandToHandSantaApplications' },
];

export const dashboardHandToHandAccordionCardValues = [
  {
    titleKey: 'completed-hand-applications-by-regions',
    dataFieldKey: 'handToHandApplicationByRegion',
    columns: [
      { Header: 'region', accessor: 'region' },
      { Header: 'quantity', accessor: 'count' },
    ],
  },
];
