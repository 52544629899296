import React, { createContext, useCallback, useContext, useState } from 'react';
import { useSearchParam } from 'react-use';

import { setSearchParam } from '@shared/utils/path';

import { DashboardCategories } from '../enums';

interface DashboardProviderProps {
  activeCategory: DashboardCategories;
  handleChangeActiveCategory: (v: string) => void;
}

export const DashboardContext = createContext({} as DashboardProviderProps);
export const useDashboardContext = (): DashboardProviderProps => useContext(DashboardContext);

function useComponentState() {
  const categoryParam = useSearchParam('category') || DashboardCategories.Volunteer;
  const [activeCategory, setActiveCategory] = useState(categoryParam as DashboardCategories);

  const handleChangeActiveCategory = useCallback((value: DashboardCategories) => {
    setActiveCategory(value);
    setSearchParam('category', value);
  }, []);

  const providerValues = {
    activeCategory,
    handleChangeActiveCategory,
  };

  return {
    providerValues,
    DashboardContext,
  };
}

export const DashboardProvider: React.FC = ({ children }) => {
  const { providerValues, DashboardContext } = useComponentState();

  return <DashboardContext.Provider value={providerValues}>{children}</DashboardContext.Provider>;
};
