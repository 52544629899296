import React from 'react';
import { DashboardAccordionCardList } from '../card-accordion-list/DashboardAccordionCardList';
import { DashboardBasicCardList } from '../card-basic-list/DashboardBasicCardList';

type Props = {
  basicCardList: any;
  accordionList: any;
};

export const DashboardCategory: React.FC<Props> = (props) => {
  const { basicCardList, accordionList } = props;

  return (
    <div className="mt-8 lg:mt-[42px] lg:flex lg:gap-x-4 xxl:gap-x-8 xxl:items-start">
      <DashboardBasicCardList list={basicCardList} />
      <DashboardAccordionCardList list={accordionList} />
    </div>
  );
};
