import { DashboardCategories } from '../enums';

export const categoryTabsState = [
  {
    labelKey: DashboardCategories.Volunteer,
    isActiveCheck: isCategoryTabActive,
    eventKey: DashboardCategories.Volunteer,
  },
  {
    labelKey: DashboardCategories.Applicants,
    isActiveCheck: isCategoryTabActive,
    eventKey: DashboardCategories.Applicants,
  },
  {
    labelKey: DashboardCategories.Applications,
    isActiveCheck: isCategoryTabActive,
    eventKey: DashboardCategories.Applications,
  },
  {
    labelKey: DashboardCategories.OnlineHelp,
    isActiveCheck: isCategoryTabActive,
    eventKey: DashboardCategories.OnlineHelp,
  },
  {
    labelKey: DashboardCategories.HandToHandHelp,
    isActiveCheck: isCategoryTabActive,
    eventKey: DashboardCategories.HandToHandHelp,
  },
  {
    labelKey: DashboardCategories.VolunteersMap,
    isActiveCheck: isCategoryTabActive,
    eventKey: DashboardCategories.VolunteersMap,
  },
];

function isCategoryTabActive(category: DashboardCategories, activeCategory: DashboardCategories) {
  return activeCategory === category;
}
