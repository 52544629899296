import { ArrowDownIcon } from '@shared/icons/arrow-down';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  titleKey: string;
  isPanelOpen: boolean;
  onClick: () => void;
}

function useComponentState(props: Props) {
  const { titleKey, isPanelOpen } = props;
  const { t } = useTranslation();
  const title = t(titleKey);

  const arrowDownCn = useMemo(
    () => clsx('relative top-[6px] w-3 h-auto shrink-0 transition-transform', isPanelOpen && 'rotate-180'),
    [isPanelOpen],
  );

  return {
    title,
    arrowDownCn,
    ...props,
  };
}

export const DashboardAccordionCardHeader = (props: Props) => {
  const { title, arrowDownCn, onClick } = useComponentState(props);

  return (
    <div
      className={'flex justify-between items-start gap-x-4 py-8 px-6 cursor-pointer'}
      onClick={onClick}
      role="button"
    >
      <span className="block">{title}</span>
      <ArrowDownIcon className={arrowDownCn} />
    </div>
  );
};
