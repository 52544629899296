import useMaxWidthMediaQuery from '@hooks/useMaxWidthMediaQuery';
import { useDashboardAccordionCardContext } from '@widgets/dashboard/card-accordion-list/DashboardAccordionCardProvider';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { useMedia } from 'react-use';
import { Button } from '../buttons/Button';

interface Props {
  pageIndex: number;
  pageSize: number;
  pageOptions: any;
  pageCount: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
  gotoPage: (value: number) => void;
}

function useComponentState(props: Props) {
  const { pageIndex, pageCount, nextPage, previousPage, gotoPage } = props;
  const { t } = useTranslation();

  const { scrollCardIntoView } = useDashboardAccordionCardContext();
  const isPaginationVisible = pageCount > 1;

  const pageCurrentOfTotal = useMemo(
    () => t('page-current-of-total', { 'current-page': pageIndex + 1, 'total-page': pageCount }),
    [pageIndex, pageCount],
  );

  const handleGoToNextPage = () => {
    nextPage();
    scrollCardIntoView();
  };
  const handleGoToPreviousPage = () => {
    previousPage();
    scrollCardIntoView();
  };
  const handleGoToFirstPage = () => {
    gotoPage(0);
    scrollCardIntoView();
  };
  const handleGoToLastPage = () => {
    gotoPage(pageCount - 1);
    scrollCardIntoView();
  };

  return {
    ...props,
    pageCurrentOfTotal,
    isPaginationVisible,
    handleGoToNextPage,
    handleGoToPreviousPage,
    handleGoToFirstPage,
    handleGoToLastPage,
  };
}

export const TablePagination: React.FC<Props> = (props) => {
  const {
    pageCurrentOfTotal,
    isPaginationVisible,
    canNextPage,
    canPreviousPage,
    handleGoToNextPage,
    handleGoToPreviousPage,
    handleGoToFirstPage,
    handleGoToLastPage,
  } = useComponentState(props);

  if (!isPaginationVisible) return null;

  return (
    <div className="flex items-center mt-6 text-xs">
      <Button
        as="button"
        variant="secondary"
        size="small"
        className="px-2 py-2"
        icon={FiChevronsLeft}
        iconClassName="w-5 h-auto"
        onClick={handleGoToFirstPage}
        disabled={!canPreviousPage}
      />
      <Button
        as="button"
        variant="secondary"
        size="small"
        className="ml-1 px-2 py-2"
        icon={FiChevronLeft}
        iconClassName="w-5 h-auto"
        onClick={handleGoToPreviousPage}
        disabled={!canPreviousPage}
      />
      <Button
        as="button"
        variant="secondary"
        size="small"
        className="ml-1 px-2 py-2"
        icon={FiChevronRight}
        iconClassName="w-5 h-auto"
        onClick={handleGoToNextPage}
        disabled={!canNextPage}
      />
      <Button
        as="button"
        variant="secondary"
        size="small"
        className="ml-1 px-2 py-2"
        icon={FiChevronsRight}
        iconClassName="w-5 h-auto"
        onClick={handleGoToLastPage}
        disabled={!canNextPage}
      />

      <span className="inline-block ml-3">
        {pageCurrentOfTotal}
        {/* Page {pageIndex + 1} of {pageCount} */}
      </span>
    </div>
  );
};
