import { useToggle } from '@hooks/useToggle';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDashboardAccordionCardContext } from '../card-accordion-list/DashboardAccordionCardProvider';
import { DashboardAccordionCardHeader } from './DashboardAccordionCardHeader';

interface Props {
  titleKey: string;
  children: any;
}

function useComponentState(props: Props) {
  const [isPanelOpen, togglePanelVisibility] = useToggle();

  const { cardRef } = useDashboardAccordionCardContext();
  const cardCn = clsx('mt-4 first:mt-0 xxl:mt-0 rounded-lg bg-white border-2 border-hawkes-blue');
  const panelCn = useMemo(() => clsx(!isPanelOpen ? 'hidden' : 'px-6 pb-6'), [isPanelOpen]);

  return {
    cardRef,
    isPanelOpen,
    cardCn,
    panelCn,
    togglePanelVisibility,
    ...props,
  };
}

export const DashboardAccordionCard: React.FC<Props> = (props) => {
  const { cardRef, cardCn, isPanelOpen, children, titleKey, panelCn, togglePanelVisibility } = useComponentState(props);

  return (
    <div ref={cardRef} className={cardCn}>
      <DashboardAccordionCardHeader titleKey={titleKey} isPanelOpen={isPanelOpen} onClick={togglePanelVisibility} />
      <div className={panelCn}>{children}</div>
    </div>
  );
};
