import * as React from 'react';
import { IconProps } from '@shared/interfaces/icon.interface';

export const ArrowDownIcon: React.FC<IconProps> = ({ width = 35, height = 35, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 12 8"
      className={className}
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.5 1.75L6 6.25l-4.5-4.5"
      ></path>
    </svg>
  );
};
