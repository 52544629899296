import { TabBasic } from '@shared/ui/tabs/TabBasic';
import React from 'react';
import { useGetCategoryTabs } from './useGetCategoryTabs';

function useComponentState() {
  const [categoryTabs, handleChangeActiveCategory] = useGetCategoryTabs();

  return { categoryTabs, handleChangeActiveCategory };
}

export const DashboardCategoryTabs = () => {
  const { categoryTabs, handleChangeActiveCategory } = useComponentState();

  return (
    <ul className="flex space-x-4 mt-6 md:mt-[42px] pb-2 md:space-x-8 overflow-x-auto scrollable">
      {categoryTabs.map((item) => (
        <TabBasic key={item.eventKey} {...item} onClick={handleChangeActiveCategory} />
      ))}
    </ul>
  );
};
