import clsx from 'clsx';
import React from 'react';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';

interface Props {
  isSorted: boolean;
  isSortedDesc: boolean;
}

function useComponentState(props: Props) {
  const { isSorted, isSortedDesc } = props;
  const arrowTopCn = isSorted ? (!isSortedDesc ? 'text-black' : '') : '';
  const arrowDownCn = clsx('absolute top-2', isSorted ? (isSortedDesc ? 'text-black' : '') : '');

  return { arrowTopCn, arrowDownCn };
}

export const TableSortIcon: React.FC<Props> = (props) => {
  const { arrowTopCn, arrowDownCn } = useComponentState(props);

  return (
    <span className="relative -top-1 flex flex-col items-center ml-2">
      <TiArrowSortedUp size={16} className={arrowTopCn} />
      <TiArrowSortedDown size={16} className={arrowDownCn} />
    </span>
  );
};
