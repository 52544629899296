export const dashboardVolunteersBasicCardValues = [
  {
    titleKey: 'volunteers-amount',
    dataFieldKey: 'totalVolunteers',
    icon: {
      name: 'superhero',
      className: 'w-[30px]',
    },
  },
  {
    titleKey: 'ukrainian-volunteers-amount',
    dataFieldKey: 'volunteersFromUkraine',
    icon: {
      name: 'flag-ukraine',
      className: 'w-[25px]',
    },
  },
  {
    titleKey: 'foreign-volunteers-amount',
    dataFieldKey: 'internationalsVolunteers',
    icon: {
      name: 'globe',
      className: 'w-[25px]',
    },
  },
  {
    titleKey: 'online',
    dataFieldKey: 'onlineVolunteers',
    icon: {
      name: 'help-online',
      className: 'w-[26px]',
    },
  },
  {
    titleKey: 'from-hand-to-hand',
    dataFieldKey: 'handToHandVolunteers',
    icon: {
      name: 'help-hands',
      className: 'w-[31px]',
    },
  },
  {
    titleKey: 'anonymous',
    dataFieldKey: 'anonymousVolunteer',
    icon: {
      name: 'superhero',
      className: 'w-[31px]',
    },
  },
];

export const dashboardVolunteersAccordionCardValues = [
  {
    titleKey: 'volunteers-amount-by-countries',
    dataFieldKey: 'volunteersByCountry',
    columns: [
      { Header: 'country', accessor: 'country' },
      { Header: 'quantity', accessor: 'count' },
    ],
  },
  {
    titleKey: 'volunteers-amount-by-regions',
    dataFieldKey: 'volunteersByRegion',
    columns: [
      { Header: 'region', accessor: 'region' },
      { Header: 'quantity', accessor: 'count' },
    ],
  },
];
