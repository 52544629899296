export const dashboardOnlineHelpBasicCardValues = [
  { titleKey: 'certificates-amount', dataFieldKey: 'totalCertificates' },
  { titleKey: 'payed-groceries-certificates', dataFieldKey: 'foodCertificates' },
  { titleKey: 'payed-pharmacy-certificates', dataFieldKey: 'medicineCertificates' },
  { titleKey: 'payed-fuel-certificates', dataFieldKey: 'fuelCertificates' },
  { titleKey: 'payed-santa-certificates', dataFieldKey: 'santaCertificatesCompleted' },
];

export const dashboardOnlineHelpAccordionCardValues = [
  {
    titleKey: 'payed-certificates-by-countries',
    dataFieldKey: 'certificateByCountries',
    columns: [
      { Header: 'country', accessor: 'country' },
      { Header: 'quantity', accessor: 'count' },
    ],
  },
];
