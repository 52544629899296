import React from 'react';

import { DashboardCategories } from '@widgets/dashboard/enums';
import { VolunteersSaturationMap } from '@widgets/maps/VolunteersSaturationMap';

import { useCategoryVisibility } from '../useCategoryVisibility';

import { useGetTotalVolunteersByCountries } from './hooks/useGetTotalVolunteersByCountries';

function useComponentState() {
  const isCategoryVisible = useCategoryVisibility(DashboardCategories.VolunteersMap);
  const totalVolunteersByCountries = useGetTotalVolunteersByCountries();

  return {
    isCategoryVisible,
    totalVolunteersByCountries,
  };
}

export const DashboardCategoryVolunteersMap = () => {
  const { isCategoryVisible, totalVolunteersByCountries } = useComponentState();

  if (!isCategoryVisible) return null;
  return (
    <div className="mt-6">
      <VolunteersSaturationMap data={totalVolunteersByCountries} />
    </div>
  );
};
