import { useGetDashboardStatisticsQuery } from '@store/api/statistics.api';
import { useGetDashboardAccordionCardList } from '@widgets/dashboard/hooks/useGetDashboardAccordionCardList';
import { useGetDashboardBasicCardList } from '@widgets/dashboard/hooks/useGetDashboardBasicCardList';
import { dashboardApplicationsAccordionCardValues, dashboardApplicationsBasicCardValues } from '../constants';

export function useGetDashboardApplicationsStatisticList() {
  const { data } = useGetDashboardStatisticsQuery();
  const applicationsData = data?.applications;
  const basicCardList = useGetDashboardBasicCardList(dashboardApplicationsBasicCardValues, applicationsData);
  const accordionCardList = useGetDashboardAccordionCardList(
    dashboardApplicationsAccordionCardValues,
    applicationsData,
  );

  const categoryProps = {
    titleKey: 'applications',
    basicCardList,
    accordionList: accordionCardList,
  };

  return categoryProps;
}
