export const dashboardApplicantsBasicCardValues = [
  { titleKey: 'applicants-amount-who-received-help', dataFieldKey: 'uniqueApplicantsWhoReceivedHelp' },
  { titleKey: 'applicants-amount', dataFieldKey: 'totalApplicants' },
];

export const dashboardApplicantsAccordionCardValues = [
  {
    titleKey: 'applicants-amount-by-regions',
    dataFieldKey: 'applicantsByRegion',
    columns: [
      { Header: 'region', accessor: 'region' },
      { Header: 'quantity', accessor: 'count' },
    ],
  },
];
