import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardBasicCardProps } from './types';

function useComponentState(props: DashboardBasicCardProps) {
  const { isFirstCard } = props;
  const { t } = useTranslation();
  const cardCn = clsx(
    'mt-4 first:mt-0 xxs:mt-0 p-6 rounded-lg border-2 border-hawkes-blue lg:mt-4 lg:first:mt-0 xxl:mt-0',
    isFirstCard ? 'bg-solitude col-span-2' : 'bg-white',
  );

  return { cardCn, t, ...props };
}

export const DashboardBasicCard: React.FC<DashboardBasicCardProps> = (props) => {
  const { t, amount, titleKey, cardCn, icon } = useComponentState(props);

  return (
    <div className={cardCn}>
      {icon && (
        <div className="mb-3">
          <GatsbyImage image={getImage(icon.image)} alt={icon.name} className={icon.className} />
        </div>
      )}
      <span className="text-4xl text-rich-blue font-medium">{amount}</span>
      <span className="block mt-[10px] text-sm xs:text-base">{t(titleKey)}</span>
    </div>
  );
};
